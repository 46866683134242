<template>
  <div class="video">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      @close="closeDialog"
    >
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-form-item label="视频名称" prop="videoName">
          <el-input v-model="form.videoName" placeholder="请输入视频名称"></el-input>
        </el-form-item>
        <el-form-item label="授课讲师">
          <el-input v-model="name" disabled></el-input>
        </el-form-item>
        <el-form-item ref="video" v-model="videoInfoId" label="视频文件" prop="video">
          <el-upload
            action="/api/third/api/huaWeiUpload/upload/video/local"
            :on-success="handleUploadSuccess"
            :file-list="fileList"
            accept=".mp4"
            :limit="1"
          >
            <el-button>选择上传素材</el-button>
          </el-upload>
          <p>仅职称.mp4格式，一次仅可上传一个</p>
        </el-form-item>
        <el-form-item>
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="onSubmit('form')">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { addVideo, searchVideo, editVideo } from '@/api/teacher'
import to from 'await-to'
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialogTitle: '添加视频',
      dialogVisible: false,
      form: { videoName: '' },
      rules: {
        videoName: [{ required: true, message: '请输入视频名称', trigger: 'blur' }],
      },
      fileList: [],
      videoInfoId: '',
      teachingVideoId: '',
    }
  },
  methods: {
    show(teachingVideoId) {
      this.dialogVisible = true
      if (teachingVideoId) {
        this.teachingVideoId = teachingVideoId
        this.searchVideoData(teachingVideoId)
      }
    },
    closeDialog() {
      this.fileList = []
      this.$refs.form.resetFields()
    },
    async handleUploadSuccess(res) {
      this.videoInfoId = res.data.assetId
      this.$message.success('上传成功')
    },
    async searchVideoData(teachingVideoId) {
      const [res, err] = await to(searchVideo({ teachingVideoId: teachingVideoId }))
      if (err) return this.$message.warning(err.msg)
      this.form.videoName = res.data.videoName
      this.fileList.push({ name: res.data.videoId, url: res.data.videoId })
      this.videoInfoId = res.data.videoId
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.teachingVideoId ? this.update() : this.created()
          this.$emit('success')
        }
      })
    },
    async created() {
      if (this.videoInfoId !== '') {
        const [, err] = await to(
          addVideo({
            videoId: this.videoInfoId,
            videoName: this.form.videoName,
            teacherId: this.id,
          }),
        )
        if (err) return this.$message.warning(err.msg)
        this.$message.success('创建成功')
        this.dialogVisible = false
      } else {
        this.$message.warning('请上传视频')
      }
    },
    async update() {
      if (this.videoInfoId !== '') {
        const [, err] = await to(
          editVideo({
            teachingVideoId: this.teachingVideoId,
            videoName: this.form.videoName,
            teacherId: this.id,
            videoId: this.videoInfoId,
          }),
        )
        if (err) return this.$message.warning(err.msg)
        this.$message.success('编辑成功')
        this.dialogVisible = false
      } else {
        this.$message.warning('请上传视频')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.video {
  ::v-deep .el-input__inner {
    width: 350px;
  }
  //   ::v-deep.uploader {
  //     .el-upload {
  //       border: 1px dashed #d9d9d9;
  //       border-radius: 6px;
  //       cursor: pointer;
  //       position: relative;
  //       overflow: hidden;
  //       position: relative;
  //       height: 120px;
  //       width: 120px;
  //       img {
  //         @extend %width-height;
  //       }
  //       .el-icon-upload {
  //         position: absolute;
  //         top: 50%;
  //         left: 50%;
  //         margin-top: -29px;
  //         margin-left: -29px;
  //         font-size: 58px;
  //       }
  //       &:hover {
  //         border-color: #ff7b33;
  //       }
  //     }
  //     .el-upload-list__item {
  //       width: 120px;
  //       height: 100%;
  //     }
  //   }
  //   ::v-deep.el-upload-dragger {
  //     background: #f5f5f5;
  //   }
  .upload {
    ::v-deep .el-upload {
      position: relative;
    }
    .el-icon-upload {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 58px;
    }
    &:hover {
      border-color: #ff7b33;
    }
  }
}
</style>
